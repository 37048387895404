import React from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import Header from '../components/header/header'
import {withPrefix} from 'gatsby-link'
import '../components/variables.css'
import '../components/style.css'
import Footer from '../components/footer/footer'

class CleanLayout extends React.Component {
    render() {
        return (
            <div className={'page ' + this.props.extraClass}>
                <Helmet>
                    <title>{`${this.props.pageTitle} | East to West`}</title>
                    <link rel='stylesheet' href={withPrefix('/css/bootstrap.css')}/>
                    <link rel='stylesheet' href={withPrefix('/css/style.css')}/>
                </Helmet>
                <Header/>
                <main className={'page-content ' + this.props.extraClass}>
                    {this.props.children}
                </main>
                <Footer/>
            </div>
        )
    }
}

CleanLayout.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    extraClass: PropTypes.string,
}

CleanLayout.defaultProps = {
    extraClass: '',
}

export default CleanLayout