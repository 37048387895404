import React from 'react'
import CleanLayout from "../layouts/clean";

export default class ContactUsPage extends React.Component {
  render() {
    return (
      <CleanLayout pageTitle={'Contact Us'} extraClass={'bg-image bg-image-7'}>
        <section className='text-center'>
          <div className='jumbotron text-center margin-large'>
            <h2>Contact East to West Development Corporation</h2>
            <p className='led'>Headquartered in Los Angeles, CA, East to West Development Corporation is a non-profit
              that
              provides affordable housing for working class families.<br />
              You can reach us via the info below or feel free to complete the form with your message. We look forward
              to hearing from you.</p>
          </div>
        </section>
        <section className='well well-sm'>
          <div className='container'>
            <div className='row row-30'>
              <div className='col-lg-8'>
                <div className='button-shadow bg-default py-5 px-3 round-large'>
                  <form className='rd-mailform text-left' data-form-output='form-output-global' data-form-type='forms'
                        method='post' action='bat/rd-mailform.php'>
                    <div className='row row-20 align-items-end'>
                      <div className='col-md-6'>
                        <div className='form-wrap form-wrap-validation validation-with-outside-label'>
                          <label className='form-label-outside' htmlFor='forms-name'>First name</label>
                          <input className='form-input' id='forms-name' type='text' name='name'
                                 placeholder='Your First Name' data-constraints='@Required' />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-wrap form-wrap-validation validation-with-outside-label'>
                          <label className='form-label-outside' htmlFor='forms-last-name'>Last name</label>
                          <input className='form-input' id='forms-last-name' type='text' name='last-name'
                                 placeholder='Your Last Name' data-constraints='@Required' />
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <div className='form-wrap form-wrap-validation validation-with-outside-label'>
                          <label className='form-label-outside' htmlFor='forms-message'>WHAT WOULD YOU LIKE TO
                            DISCUSS?</label>
                          <textarea className='form-input' id='forms-message' name='message'
                                    placeholder='Write your message here' data-constraints='@Required' />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-wrap form-wrap-validation validation-with-outside-label'>
                          <label className='form-label-outside' htmlFor='forms-email'>E-mail</label>
                          <input className='form-input' id='forms-email' type='email' name='email'
                                 placeholder='example@domain.com' data-constraints='@Email @Required' />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <button className='button button-primary button-xs' type='submit'>Send Message</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className='col-lg-4 text-center text-lg-left'>
                <address className='contact-block bg-default button-shadow py-5 px-4 round-large'>
                  <dl className='list-md'>
                    <dt className='heading-6'>PHONE</dt>
                    <dd><a href='tel:3055882638'>(305) 588-2638</a></dd>
                    <dt className='heading-6'>E-MAIL</dt>
                    <dd><a href='mailto:daniel@etwdc.org'>Daniel@etwdc.org</a></dd>
                  </dl>
                </address>
              </div>
            </div>
          </div>
        </section>
      </CleanLayout>
    )
  }
}